import React from "react"
import AppBar from "@mui/material/AppBar";
import {createTheme, Link, ThemeProvider, Toolbar, Typography} from "@mui/material";

interface Props {
    title: string
}

const HeaderBar = (props: Props) => {

    const theme = createTheme();
    theme.typography.h3 = {
        fontSize: '1.4rem',
        '@media (min-width:600px)': {
            fontSize: '1.5rem',
        },
        '@media (min-width:750px)': {
            fontSize: '1.6rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.8rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '2.6rem',
        },
    };

    return <AppBar
        position="fixed"
        sx={{height: "64px", background: "rgb(51,51,51,0.9)"}}
    >
        <Toolbar sx={{justifyContent: "center", textAlign: "center"}}>
            <Link href="/" mr={4}>
                <img
                    alt="Silvertip Tours"
                    style={{height: "58px", marginTop:"10px"}}
                    src="/assets/silvertip-logo-notext.png"/>
            </Link>
            <ThemeProvider theme={theme}>
                <Typography
                    variant="h3"
                    sx={{
                        fontWeight: 400,
                        color: 'white',
                        fontVariant: 'small-caps',
                    }}
                >
                    {props.title}
                </Typography>
            </ThemeProvider>
        </Toolbar>
    </AppBar>
}

export default HeaderBar