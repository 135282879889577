import React, {useCallback, useEffect, useState} from "react"
import {useImmer} from "use-immer";
import API from "../../../../Api/Api";
import {
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, IconButton, InputLabel,
    MenuItem, Select,
    Stack, TextField,
    Typography
} from "@mui/material";


import {TFunction} from "i18next";
import {ArrowDownward, ArrowUpward, Delete, Edit} from "@mui/icons-material";
import MgmtPageActions from "../../Components/MgmtPageActions.tsx";
import utils from "../../../../Common/Utils.tsx";
import MgmtPageSaveClose from "../../Components/MgmtPageSaveClose.tsx";
import MgmtPageTitle from "../../Components/MgmtPageTitle.tsx";

interface Props {
    t: TFunction
    onChange: any
    filter: string
    values: any
    title?: string
    hasItemTitle?: boolean
}

const TourEditText = (props: Props) => {

    const t = props.t

    interface TextModule {
        id: number | null
        type: string
        name: string
        text: string
        actionID: string | null
    }

    interface Item {
        id: number | null
        item: string
        order: number
        toDelete: boolean
        actionID: string | null
        title?: string
    }

    interface Options {
        key: number
        value: number
        text: string
        name: string
    }

    const [items, setItems] = useImmer<Item[]>([])
    const [options, setOptions] = useState<Options[]>([])
    const [textModules, setTextModules] = useState<TextModule[]>([])
    const [loading, setLoading] = useState(true)
    const [showDialogFreeText, setShowDialogFreeText] = useState(false)
    const [editItem, setEditItem] = useState('')
    const [undoItem, setUndoItem] = useState<Item | null>(null)
    const [showDialogTextModule, setShowDialogTextModule] = useState(false)
    const [maxOrder, setMaxOrder] = useState<number>(0)
    const [minOrder, setMinOrder] = useState<number>(1)
    const [isNew, setIsNew] = useState<boolean>(false)

    const updateOptions = useCallback((modules: any, values: TextModule[]) => {
        const newOptions: Options[] = []
        modules.forEach((d: any) => {
            if (values && !values.find((t: any) => {
                return d.id === t.id
            })) {
                newOptions.push({
                    key: d.id,
                    value: d.id,
                    text: d.text,
                    name: d.name,
                })
            } else if (!values) {
                newOptions.push({
                    key: d.id,
                    value: d.id,
                    text: d.text,
                    name: d.name,
                })
            }
        })
        if (newOptions.length > 0) {
            setOptions(newOptions)
        }
    }, [])

    // Load initial values
    useEffect(() => {
        if (loading) {
            if (props.values) {
                setItems(props.values.map((i: Item) => {
                    return {...i, actionID: utils.makeId()}
                }))
                let max = 0
                let min = 9999
                props.values.forEach((i: Item) => {
                    if (i.order > max) max = i.order
                    if (i.order < min) min = i.order
                })
                setMaxOrder(max)
                setMinOrder(min)
            }
            API.Request('/tour/text').then((d: any) => {
                const modules = d.payload.filter((d: any) => d.type === props.filter || d.type === "universal")
                setTextModules(modules)
            })
            setLoading(false)
        }
    }, [loading, props, setItems, updateOptions])

    const onChangeItem = (text: string, actionID: string, field: string) => {
        const item = items.find((d: any) => d.actionID === actionID)
        if (item === undefined) return
        const index = items.findIndex((d) => d.actionID === actionID)
        setItems((draft) => {
            draft.splice(index, 1)
            draft.push({...item, [field]: text})
        })
    }

    const saveNewItem = (value: Item) => {
        const multi = value.item.split(/\r?\n|\r|\n/g);
        const newItems = [...items]
        if (multi.length > 1 && !props.hasItemTitle) {
            newItems.splice(newItems.findIndex((i) => i.actionID === value.actionID), 1)
            multi.forEach((i: any, index: number) => {
                    const o = maxOrder + 1 + index
                    newItems.push({
                        item: i,
                        order: o,
                        toDelete: false,
                        id: null,
                        actionID: utils.makeId()
                    })
                    setMaxOrder(o)
                }
            )
            setItems(newItems)
        } else {
            setMaxOrder(maxOrder + 1)
        }
        setIsNew(false)
        setShowDialogFreeText(false)
        props.onChange(newItems)
    }

    const onSelect = (value: any) => {
        console.log("onSelect: ", JSON.stringify(value))
        if (value === -1) return
        const toAdd = textModules.find((m) => {
            return m.id === value
        })
        if (toAdd) {
            props.onChange([...items, {
                id: null,
                order: maxOrder + 1,
                toDelete: false,
                item: toAdd.text,
                actionID: utils.makeId(),
                title: props.hasItemTitle ? toAdd.name : null
            }])
            setLoading(true)
        }

        const newOptions = options.filter((d) => {
            return d.value !== value
        })
        setOptions(newOptions)
        setShowDialogTextModule(false)
    }

    const onDeleteItem = (id: string) => {
        console.log("id: ", id)
        if (!id) return
        // remove new unsaved items
        const rmNew = items.filter((item) => !(item.actionID === id && item.id === null))
        // set toDelete
        const newItems = rmNew.map((item) => {
            if (item.actionID === id) {
                return {...item, toDelete: true}
            } else {
                return {...item}
            }
        })
        setEditItem('')
        setMaxOrder(0)
        setMinOrder(0)
        setShowDialogTextModule(false)
        setIsNew(false)
        props.onChange(newItems)
        setLoading(true)
    }

    const filterToDelete = (items: any) => {
        if (items) {
            return items.filter((i: any) => !i.toDelete)
        } else {
            return []
        }
    }

    const createNewItem = () => {
        const item = {actionID: utils.makeId(), item: "", id: null, order: maxOrder + 1, toDelete: false}
        setItems((draft) => {
            draft.push(item)
        })
        setEditItem(item.actionID)
        setIsNew(true)
        setShowDialogFreeText(true)
    }

    const undoChange = () => {
        if (!undoItem) {
            onDeleteItem(editItem)
        } else {
            const index = items.findIndex((d) => d.actionID === editItem)
            setItems((draft) => {
                draft.splice(index, 1)
                draft.push(undoItem)
            })
            setUndoItem(null)
        }
        setIsNew(false)
        setShowDialogFreeText(false)
    }

    const DialogFreeText = () => {
        const item = editItem ? items.find((i: any) => i.actionID === editItem) : undefined
        if (item === undefined) return
        return <Dialog open={showDialogFreeText} onClose={() => undoChange()} fullWidth maxWidth={"lg"}>
            <DialogTitle>
                <MgmtPageTitle label={isNew ? t("mgmtTourEdit.addNewItem") : t("mgmtTourEdit.updateItem")} icon={'list'}
                               disableBorder={true}/>
            </DialogTitle>
            <DialogContent>
                {props.hasItemTitle ?
                    <TextField
                        sx={{flex: 1}}
                        value={item.title}
                        onChange={(e) => {
                            onChangeItem(e.target.value, editItem, 'title')
                        }}
                        autoFocus
                        fullWidth
                        margin="dense"
                        id="title"
                        name="title"
                        label={isNew ? t("mgmtTourEdit.newTitle") : t("mgmtTourEdit.updateTitle")}
                        type="text"
                        variant="standard"
                    /> : ''
                }
                <TextField
                    sx={{flex: 1}}
                    multiline
                    value={item.item}
                    onChange={(e) => {
                        onChangeItem(e.target.value, editItem, 'item')
                    }}
                    autoFocus={!props.hasItemTitle}
                    fullWidth
                    margin="dense"
                    id="item"
                    name="item"
                    label={isNew ? t("mgmtTourEdit.newItem") : t("mgmtTourEdit.updateItem")}
                    type="text"
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <MgmtPageSaveClose
                    disabled={canSave(item, undoItem)}
                    onSave={() => saveNewItem(item)}
                    updateSelector={!isNew}
                    discard={() => undoChange()}/>
            </DialogActions>
        </Dialog>
    }

    const canSave = (item: Item, undoItem: Item | null) => {
        if (props.hasItemTitle) {
            return !Boolean(item.item) || !Boolean(item.title) ||
                Boolean(undoItem && undoItem.item === item.item && undoItem.title === item.title)
        } else {
            return !Boolean(item.item) || Boolean(undoItem && undoItem.item === item.item)
        }
    }
    const DialogTextModule = () => {
        return <Dialog open={showDialogTextModule} onClose={() => setShowDialogTextModule(false)}
                       fullWidth maxWidth={"lg"}>
            <DialogTitle>
                {t("mgmtTourEdit.addTextModule")}
            </DialogTitle>
            <DialogContent>
                <FormControl variant="standard" sx={{m: 1, width: "100%"}}>
                    <InputLabel> {t("mgmtTourEdit.textModule")}</InputLabel>
                    <Select
                        id="textModule"
                        name="textModule"
                        variant="standard"
                        value={""}
                        margin="dense"
                        onChange={(event) => onSelect(event.target.value)}
                        label="Type"
                        SelectDisplayProps={{
                            style: {display: 'flex', alignItems: 'center'},
                        }}>
                        {textModules.map((option) => {
                            return <MenuItem value={option.id ? option.id : undefined} key={option.id}>
                                <Typography variant="subtitle2">Name:</Typography>
                                <Typography variant="body1" ml={1}> {option.name}</Typography>
                                <Typography variant="subtitle2" ml={2}>Text:</Typography>
                                <Typography variant="body1" ml={1}> {option.text}</Typography>
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
            </DialogContent>
        </Dialog>
    }

    const RenderList = () => {
        if (props.hasItemTitle) {
            return filterToDelete(sortItems()).map((item: any) => {
                return RenderTitleItem(item)
            })
        } else {
            return filterToDelete(sortItems()).map((item: any) => {
                return RenderItem(item)
            })
        }
    }

    const sortItems = () => {
        return [...items].sort((a: Item, b: Item) => {
            return a.order > b.order ? 1 : -1
        })
    }

    const stepUpDown = (order: number, down?: boolean) => {
        let shifter = 1
        let offset = 1
        if (down) {
            shifter = -1
            offset = 0
        }
        const sorted = sortItems()
        const index = sorted.findIndex((item: any) => item.order === order)
        const replace = [{...sorted[index - shifter], order: order}, {
            ...sorted[index],
            order: sorted[index - shifter].order
        }]
        sorted.splice(index - offset, 2)
        props.onChange([...sorted, ...replace])
        setLoading(true)
    }

    const RenderTitleItem = (item: Item) => {
        return <Stack direction={"row"} key={item.actionID}
                      style={{borderBottom: "1px dotted lightgray", marginTop: "1em"}}>
            <Typography variant="body1" ml={1}
                        sx={{display: "contents", fontWeight: 600}}>{item.title}</Typography><br/>
            <Typography variant="body1" ml={1} sx={{display: "contents", textAlign: "justify"}}>{item.item}</Typography>
            <div style={{display: "flex", flexBasis: "min-content", marginRight: "0px", marginLeft: "auto"}}>
                {/*<Typography variant="caption"*/}
                {/*            alignItems="center">{id + "=>" + order + ":" + minOrder + "<>" + maxOrder}</Typography>*/}
                <IconButton color="primary" size="small" onClick={() => stepUpDown(item.order)}
                            disabled={Boolean(item.order <= minOrder)}>
                    <ArrowUpward fontSize="small"/>
                </IconButton>
                <IconButton color="primary" size="small" onClick={() => stepUpDown(item.order, true)}
                            disabled={Boolean(item.order >= maxOrder)}>
                    <ArrowDownward fontSize="small"/>
                </IconButton>
                <IconButton color="primary" aria-label="edit list item" size="small"
                            onClick={() => {
                                setUndoItem(item)
                                setEditItem(item.actionID ? item.actionID : '')
                                setShowDialogFreeText(true)
                            }}>
                    <Edit fontSize="small"/>
                </IconButton>
                <IconButton color="warning" aria-label="delete list item" size="small"
                            onClick={() => {
                                onDeleteItem(item.actionID ? item.actionID : '')
                            }}>
                    <Delete fontSize="small"/>
                </IconButton>
            </div>
        </Stack>

    }
// RenderItem returns item line with bullet and action buttons using the id as identifier for actions
// If disableEdit is true, the edit action button will be disabled
    const RenderItem = (item: Item) => {
        return <Stack direction={"row"} key={item.actionID}
                      style={{borderBottom: "1px dotted lightgray", marginTop: "1em"}}>
            <Typography variant="body1"
                        style={{
                            position: "relative",
                            top: "8px",
                            left: "-5px",
                            width: "6px",
                            height: "6px",
                            borderRadius: "50%",
                            border: "2px solid black"
                        }}/>
            <Typography variant="body1" ml={1} sx={{display: "contents"}}>{item.item}</Typography>
            <div style={{marginLeft: "auto", marginRight: "0px"}}>
                {/*<Typography variant="caption"*/}
                {/*            alignItems="center">{id + "=>" + order + ":" + minOrder + "<>" + maxOrder}</Typography>*/}
                <IconButton color="primary" size="small" onClick={() => stepUpDown(item.order)}
                            disabled={Boolean(item.order <= minOrder)}>
                    <ArrowUpward fontSize="small"/>
                </IconButton>
                <IconButton color="primary" size="small" onClick={() => stepUpDown(item.order, true)}
                            disabled={Boolean(item.order >= maxOrder)}>
                    <ArrowDownward fontSize="small"/>
                </IconButton>
                <IconButton color="primary" aria-label="edit list item" size="small"
                            onClick={() => {
                                setUndoItem(item)
                                setEditItem(item.actionID ? item.actionID : '')
                                setShowDialogFreeText(true)
                            }}>
                    <Edit fontSize="small"/>
                </IconButton>
                <IconButton color="warning" aria-label="delete list item" size="small"
                            onClick={() => {
                                onDeleteItem(item.actionID ? item.actionID : '')
                            }}>
                    <Delete fontSize="small"/>
                </IconButton>
            </div>
        </Stack>
    }

    const RenderTitle = ({title}: any) => {
        return <Typography
            variant="h6"
            fontSize="1em"
            style={{marginLeft: "-7px", borderBottom: "lightgray solid 1px"}}>
            {title}
        </Typography>
    }
    const actions = [
        {label: "mgmtTourEdit.addTextModule", onClick: () => setShowDialogTextModule(true)},
        {label: "mgmtTourEdit.addText", onClick: () => createNewItem()},
    ]

    return <>
        {props.title ? <RenderTitle title={props.title}/> : ''}
        <MgmtPageActions actions={actions} style={{marginLeft: "-11px"}}/>
        <RenderList/>
        <DialogTextModule/>
        {DialogFreeText()}
    </>
}

export default TourEditText