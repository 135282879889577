import React, {useEffect, useState} from "react"
import TourApi from "../../Api/TourApi";
import PhotoBar from "./PhotoBar/PhotoBar";
import {
    Box, Fade, Typography, Container, useScrollTrigger, Stack, createTheme,
    Paper,
} from "@mui/material";
import HeaderTour from "./HeaderTour";
import HeaderBar from "./HeaderBar";
import TourProgram from "./TourProgram";
import TourTextList from "./TourTextList";
import TourSeason from "./TourSeason";
import TourPrice from "./TourPrice";
import TourGroupDates from "./TourGroupDates";
import TourService from "./TourService";
import TourInfo from "./TourInfo";
import Utils from "../../Common/Utils.tsx";

interface Props {
    id: number
}

const ViewTour = (props: Props) => {

    const [tour, setTour] = useState(TourApi.TourDefinition)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (loading)
            TourApi.GetPublicTour(props.id).then((d: any) => {
                setTour(d.payload)
                setLoading(false)
            })
    }, [setTour, loading, props.id])

    const joinToString = (a: any, name: string) => {
        if (loading) return ""
        if (!a) return ""
        const s = a.map((i: any) => {
            return i[name]
        })
        return s.join(", ")
    }

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 550,
    });

    const triggerInfo = useScrollTrigger({
        disableHysteresis: true,
        threshold: 800,
    });

    const triggerProgram = useScrollTrigger({
        disableHysteresis: true,
        threshold: 200,
    });

    const triggerPhoto = useScrollTrigger({
        disableHysteresis: true,
        threshold: 1200,
    });

    // Modify the tour header font size for mobiles
    const theme = createTheme();
    theme.typography.h3 = {
        fontSize: '1.4rem',
        '@media (min-width:600px)': {
            fontSize: '1.5rem',
        },
        '@media (min-width:750px)': {
            fontSize: '1.6rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.8rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '2.6rem',
        },
    };

    const combineImpressions = () => {
        const combined = []
        if (tour.photo) combined.push(...tour.photo)
        if (tour.photoSet && tour.photoSet.photo) combined.push(...tour.photoSet.photo)
        return combined
    }

    const addGeneralInfo = () => {
        const info = []
        info.push({name: "Tour Code", text: tour.code})
        if (tour.language) info.push({name: "Toursprache", text: joinToString(tour.language, "name")})
        // if (tour.guidedBy) info.push({name: "Reiseleitung", text: tour.guidedBy})
        if (tour.accommodation) info.push({name: "Unterbringung", text: tour.accommodation})
        if (tour.season) info.push({name: "Reisezeit", text: Utils.makeSeason(tour.season)})
        if (tour.info && tour.info.length > 0) info.push(...tour.info)
        return info
    }

    const RenderCharacter = () => {
        return <Paper
            elevation={0}
            sx={{
                background: "rgb(69,147,198,0.3)",
                p: 2,
                flex: 0.4,
                height: "100%"
            }}
        >
            <Typography variant='h6'>Charakter</Typography>
            <Typography variant='body1' textAlign='justify' mt={1}>{tour.character}</Typography>
        </Paper>
    }

    return <>
        {trigger ? <HeaderBar title={tour.name}/> : ""}
        <HeaderTour tour={tour}/>
        <Container>
            <Stack id="tour-description" mt={4} spacing={{xs: 2, md: 4}} direction={{xs: "column", md: "row"}}
                   useFlexGap flexWrap="wrap">
                <Box flex={1}>
                    <Typography variant="body1" textAlign="justify" fontSize={"1.2rem"}>
                        {tour.description}
                    </Typography>
                </Box>
            </Stack>

            <Fade in={triggerProgram} timeout={2000}>
                <div>
                    <Stack id="tour-program" mt={5}>
                        <TourProgram program={tour.program}/>
                    </Stack>
                    <TourInfo info={addGeneralInfo()} infoText={tour.info}/>
                </div>
            </Fade>

            <Fade in={triggerInfo} timeout={2000}>
                <div>
                    <Stack id="tour-infos" spacing={{xs: 1, md: 3}} direction="column" useFlexGap flexWrap="wrap"
                           mt={3}>
                        <TourService
                            title="Was ist inkludiert"
                            items={tour.include}
                            textModules={tour.textInclude}
                        />
                        <TourService
                            title="Was ist nicht inkludiert"
                            items={tour.exclude}
                            textModules={tour.textExclude}
                        />
                    </Stack>
                    {tour.character ?
                        <Stack id="tour-character" mt={3}>
                            <RenderCharacter/>
                        </Stack>
                        : ""}
                </div>
            </Fade>

            {tour.dates ?
                <Stack sx={{mt: 4}}>
                    <TourGroupDates dates={tour.dates}/>
                    <TourPrice filter="group" price={tour.price}/>
                    <TourTextList text={tour.textDate} addons={tour.dateAddon}/>
                </Stack> : ""
            }

            {tour.price ?
                <Stack sx={{mt: 4}}>
                    <TourPrice filter="pax" price={tour.price}/>
                    <TourPrice filter="option" price={tour.price}/>
                    <Stack pt={2}/>
                    <TourSeason season={tour.season}/>
                    <TourTextList text={tour.textIndividual} addons={tour.individualAddon}/>
                </Stack> : ""
            }

            <Stack id="tour-photo" mt={4}>
                <Fade in={triggerPhoto} timeout={2000}>
                    <div><PhotoBar photos={combineImpressions()}/></div>
                </Fade>
            </Stack>
        </Container>
    </>
}

export default ViewTour
